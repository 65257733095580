.ag-theme-custom {
  color: #343a40;
  height: calc(100%);
  /* max-height: 14px; */
  /* min-height: 14px; */

  --ag-border-color: ##c1c1c1; /* 그리드 전체 외곽 색상*/

  --ag-header-foreground-color: #343a40; /* 헤더 폰트(+필터) 색상*/
  --ag-header-background-color: white; /* 헤더 배경 색상*/

  /* --ag-header-cell-hover-background-color: 헤더 호버 색상*/
  --ag-header-cell-hover-background-color: rgba(237, 237, 237, 0.6);
  --ag-header-cell-moving-background-color: #266bff; /* 헤더 잡고 이동시킬 때 색상*/
  --ag-header-cell-moving-color: white; /* 헤더 잡고 이동시킬 때 색상*/

  /* font-weight: normal; */ /* 헤더 폰트 굵기 */
  /* font-size: 13px; */ /* 헤더 폰트 사이즈 */

  --ag-header-column-resize-handle-width: 1px; /* 헤더 좌우 카테고리간 구분짓는 선 굵기 */
  --ag-header-column-resize-handle-height: 20%;
  --ag-header-column-resize-handle-color: none;

  /* --ag-odd-row-background-color: #f8f9fa; */ /* 바디 격줄 배경 */
  --ag-odd-row-background-color: white; /* 바디 격줄 배경 */

  --ag-foreground-color: #343a40; /* 바디 폰트 색상 */
  /* --ag-row-hover-color: #f8f9fa; */ /* 바디 호버 색상 */

  --ag-range-selection-border-style: none; /* 바디에 있는 셀 클릭시 테두리 스타일 */
  --ag-range-selection-border-color: #c1c1c1; /* 바디에 있는 셀 클릭시 테두리 색상 */
}

.ag-root ::-webkit-scrollbar {
  /* x축 스크롤바 높이 */
  /* height: 14px; */

  /* max-height: 14px; */
  /* min-height: 14px; */
}

.ag-root ::-webkit-scrollbar-thumb {
  border-radius: 0.5em;
  background-color: #c1c1c1;
}

.ag-root ::-webkit-scrollbar-track-piece {
  border-radius: 0.5em;
  background-color: #f8f9fa;
  border-bottom: '#c1c1c1';
}

.ag-root ::-webkit-scrollbar-button {
  width: 0;
}

.ag-paging-panel {
  /* ag-paging-panel: 하단 페이지네이션 버튼 있는 박스*/
  border: none;
  color: #343a40;

  #ag-4-to {
    color: #a0a0a0;
  }

  #ag-4-of {
    color: #a0a0a0;
  }

  #ag-4-start-page {
    color: #a0a0a0;
  }

  #ag-4-of-page {
    color: #a0a0a0;
  }
}

.ag-row {
  /* border-style: none; */ /* 본문 구분선 스타일 */
  /* border-bottom: solid 1px #c1c1c1; */ /* 본문 구분선 스타일 */
  border-bottom: none;
  display: flex;
  justify-content: center;

  &:hover {
    color: #266bff; /* 본문 호버시 폰트 색상 */
  }
}

/* .ag-header-container: 헤더 부분(헤더에 있는 셀 리스트) */
.ag-header-container {
  padding-left: 30px;
}

.ag-header-cell {
  padding: 0;
  border: none;
}

.ag-header-viewport {
  border-bottom: solid 1px #c1c1c1;
}

.ag-center-cols {
  height: calc(100% + 14px);
}

/* 바디 영역 전체 컨테이너 */
.ag-full-width-container {
  /* background-color: #266bff; */
}

.ag-body-horizontal-scroll {
  height: 14px;
  max-height: 14px;
  min-height: 14px;
}

.ag-body-horizontal-scroll-viewport {
  height: 14px;
  max-height: 14px;
  min-height: 14px;
}

.ag-body-horizontal-scroll-container {
  height: 14px;
  max-height: 14px;
  min-height: 14px;
}

/* 각 카테고리 이름 우측에 있는 아이콘 (햄버거바 아님! 플라스크같은모양, 너비작아서안보임) */
.ag-header-icon {
  /* width: 20px; */
}

/* 헤더에 있는 카테고리 개별(햄버거바 말고 텍스트만) */
.ag-header-cell-label {
  display: flex;
  /* justify-content을 통해 헤더 셀 정렬 */
  justify-content: start;
  align-items: center;
}

/* 각 카테고리 이름 우측에 있는 햄버거바 */
.ag-icon-menu {
  width: 15px;
}

/* 헤더 행이 여러 개일 때, 맨 밑을 제외한 나머지 위쪽 부분 스타일 */
.ag-header-group-cell-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ag-cell {
  padding: 0;
  border: none;
}

/* .ag-center-cols-container: 바디 부분(바디에 있는 셀 리스트 스타일) */
.ag-center-cols-container {
  padding-left: 30px;
}

/* 행 눌렀을 때 스타일(행이 선택될 때의 스타일) */
.ag-row-focus.ag-row-hover {
  /* border: solid 1px #c1c1c1; */
  border: none;
}

/* 바디 셀 눌렀을 때 스타일(바디에 있는 셀이 선택될 때의 스타일) */
.ag-cell-value.ag-cell-focus {
  border: none;
}

/* 바디 셀 눌렀을 때 스타일(바디에 있는 셀이 선택될 때의 스타일) */
.ag-cell-focus:focus {
  /* 테두리 색상 변경을 원할시,
   * 위쪽 .ag-theme-custom 클래스 내
   * --ag-range-selection-border-color에서 변경할 것
   */
  /* background-color: #ededed; */
  background-color: rgba(237, 237, 237, 0.6);
}

/* 이하 커스텀 스타일 */
/* .cell-cell: 커스텀 클래스(셀) */
.cell-class {
  font-size: 13px;
  font-weight: 600;
  /* background-color: olive; */
}

/* .cell-class-contents: 커스텀 클래스(셀) */
.cell-class-contents {
  font-size: 13px;
}

/* .cell-class-button: 커스텀 클래스(셀) */
.cell-class-button {
  font-size: 13px;
}

/* .header-class-cell: 커스텀 클래스(헤더) */
.header-class-center {
  ::before {
    content: '';
  }
}

/* .header-class-none-text: 커스텀 클래스(헤더) */
.header-class-none-text {
  cursor: default;

  ::before {
    content: '';
  }
}

/* .header-custom-CV302-1: 커스텀 클래스(CV302) */
.header-custom-CV302-1 {
  color: #343a40;
  border-right: solid 1px #ededed;
}

/* .header-custom-CV302-2: 커스텀 클래스(CV302) */
.header-custom-CV302-2 {
  color: #343a40;
  border-right: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
}
