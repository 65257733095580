.ag-theme-custom {
  color: #343a40;
  font-weight: normal; /* 헤더 폰트 굵기 */
  font-size: 13px; /* 헤더 폰트 사이즈 */

  --ag-odd-row-background-color: #f8f9fa; /* 본문 격줄 색상 */
  --ag-foreground-color: #343a40; /* 본문 폰트 색상 */
  --ag-row-hover-color: #f8f9fa; /* 본문 호버 색상 */

  --ag-border-color: ##c1c1c1; /* 그리드 전체 외곽 색상*/

  --ag-header-foreground-color: white; /* 헤더 폰트(+필터) 색상*/
  --ag-header-background-color: #1466ff; /* 헤더 배경 색상*/
  --ag-header-cell-hover-background-color: #004fd7;
  --ag-header-cell-moving-background-color: #004fd7;

  --ag-header-column-resize-handle-width: 1px; /* 헤더 카테고리간 구분짓는 선 굵기 */
  --ag-header-column-resize-handle-height: 20%;
  --ag-header-column-resize-handle-color: white;
}

.cell-class {
  font-size: 13px;
  font-weight: 600;

  /* background-color: olive; */
}

.cell-class-contents {
  font-size: 13px;
}
