.react-datepicker {
  font-size: 14px;
  border: 1px solid #c1c1c1;
  /* border: 1px solid #e0e0e0; */
}

/* MEMO: 달력 헤더 (년, 월, 요일을 포함하고 있는 박스) */
.react-datepicker__header {
  padding: 10px;
  border-bottom: 1px solid #c1c1c1;
  /* background-color: #c1c1c1; */
  background-color: rgba(237, 237, 237, 0.6);
}

/* MEMO: 달력 헤더에 있는 년, 월 */
.react-datepicker__current-month {
  margin-bottom: 5px;
  color: #1466ff;
  /* font-weight: 500; */
}

/* MEMO: 달력 헤더에 있는 년 드롭박스 */
.react-datepicker__year-select {
  padding: 2px;
  /* border: solid 1px #c1c1c1; */
  border: solid 1px #e0e0e0;
  border-radius: 0.5em;
  /* color: #1466ff; */
  color: #343a40;
  font-weight: 500;
}

/* 👇 스크롤바 스타일 */
::-webkit-scrollbar {
  width: 0.8em;
  height: 0.8em;
}
::-webkit-scrollbar-thumb {
  border-radius: 0.5em;
  background-color: #c1c1c1;
}
::-webkit-scrollbar-track {
  border-radius: 0.5em;
  background-color: #f8f9fa;
  border-bottom: '#c1c1c1';
}
/* 👆 스크롤바 스타일 */

/* MEMO: 달력 헤더에 있는 월 드롭박스 */
.react-datepicker__month-select {
  padding: 2px;
  /* border: solid 1px #c1c1c1; */
  border: solid 1px #e0e0e0;
  border-radius: 0.5em;
  /* color: #1466ff; */
  color: #343a40;
  font-weight: 500;
}

/* MEMO: 달력의 일~토 부분 */
.react-datepicker__day-names {
  font-weight: 500; /* 👀 */
}

/* MEMO: 달력 본문 (날짜를 포함하고 있는 박스) */
.react-datepicker__month {
  margin: 10px;
  font-weight: 500; /* 👀 */
}

/* MEMO: 달력 본문에서 현재 날짜(or 선택된 날짜) */
.react-datepicker__day--selected {
  background-color: #1466ff;
}

/* MEMO: 달력 본문에서 현재 '일'(다른 선택된 날짜가 있는 경우, 오늘 날짜에 해당되는 '일' 스타일) */
.react-datepicker__day--keyboard-selected {
  background-color: #e0e0e0;

  &:hover {
    background-color: #1466ff;
    color: white;
  }
}
/* react-datepicker__day react-datepicker__day--011 react-datepicker__day--in-range react-datepicker__day--in-selecting-range */
/* 기간 선택을 위한 두 개의 달력 중 '다음에 해당하는 달력' - 기존 설정한 영역 */
.react-datepicker__day--in-selecting-range {
  /* background-color: #0848d1; */
  background-color: #717171;

  &:hover {
    background-color: #1466ff;
  }
}

.react-datepicker__day-selected react-datepicker__day--in-selecting-range {
  /* background-color: #0848d1; */
  background-color: red;
}

/* 기간 선택을 위한 두 개의 달력 중 '다음에 해당하는 달력' - 선택한 날짜 */
.react-datepicker__day--selecting-range-end {
  background-color: #1466ff;
  /* background-color: #717171; */
}

/* MEMO: 달력 본문에서 각각의 날짜 박스 */
.react-datepicker__day-name,
.react-datepicker__day {
  width: 2em;
  line-height: 2em;
}

.react-datepicker__navigation {
  /* top: 1em; */
  line-height: 1.65em;
  /* border: 0.45em solid transparent; */

  /* MEMO: 달력 헤더에 있는 왼쪽, 오른쪽 이동 */
  ::before {
    border-width: 2px 2px 0 0;
  }
}

/* MEMO: 달력 헤더에 있는 왼쪽으로 이동 */
.react-datepicker__navigation--previous {
  left: 1em;
}

/* MEMO: 달력 헤더에 있는 오른쪽으로 이동 */
.react-datepicker__navigation--next {
  right: 1em;
}

/* MEMO: 달력 인풋 (날짜가 위치한 곳이 인풋으로 되어있음) */
.react-datepicker__input-container {
  input {
    width: 100%;
    padding: 5px 10px;
    border: solid 1px #1466ff;
    border-radius: 0.5em;
    background-color: white;
  }
}
